import { registerApplication, start, setMountMaxTime } from "single-spa";
import {
    constructApplications,
    constructRoutes,
    constructLayoutEngine
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
    routes,
    loadApp({ name }) {
        return System.import(name);
    }
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
// After 10 seconds, show a console warning while continuing to wait.
setMountMaxTime(10000);
